/* .Background_Style {
    background-image: url('https://s3-us-west-2.amazonaws.com/pubgoer-pics/jrowe-mobile-background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
} */

.Background_Style:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url('https://s3-us-west-2.amazonaws.com/pubgoer-pics/beach_walk.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
.DottedBox {
    margin: 40px;
    border: 5px dotted white;
}
  
.DottedBox_content {
    font-size: 15px;
    text-align: center;
}

.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Aligner-item {
    max-width: 50%;
}

.Aligner-item--top {
    align-self: flex-start;
}

.Aligner-item--bottom {
    align-self: flex-end;
}

@media (min-width: 750px) {
    /* .Background_Style {
        background-image: url('https://s3-us-west-2.amazonaws.com/pubgoer-pics/beach_walk.jpg');
        background-attachment:fixed;
        background-position:center;
        background-repeat: no-repeat;
        background-size: 100vw 100vh;
    }
    */
}