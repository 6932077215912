html {
	position: relative;
	min-height: 100%;
}

body {
  min-height: 100%;
  font-size: 18px;
}

p {
	font-size: 18px;
}

li {
	font-size: 18px;
}
